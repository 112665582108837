/**
 * Import this as early as possible in the app's lifecycle, per Sentry's recommendation.
 *
 * Usage:
 * import '/sentry.ts';
 */

import * as Sentry from '@sentry/react';

import type { ConfigData } from './shared/client-config';

const dsn: string | undefined = process.env.PUBLIC_SENTRY_DSN;
const env: string | undefined = process.env.PUBLIC_ENV;
const isDebug = process.env.PUBLIC_SENTRY_DEBUG === 'true';
const isEnabled = process.env.PUBLIC_SENTRY_ENABLED === 'true';

if (isEnabled) {
  try {
    Sentry.init({
      debug: isDebug,
      dsn,
      enabled: isEnabled,
      environment: env,
      integrations: [Sentry.browserTracingIntegration()],
      release: process.env.PUBLIC_SENTRY_RELEASE,
      tracesSampleRate: isDebug ? 1.0 : 0.2,
    });
  } catch (err) {
    console.warn('Failed to initialise Sentry', err);
  }
} else {
  console.debug('Sentry is disabled!');
}

/**
 * Handle integration with the way we get config. If an error occurs before this gets called, these values won't be searchable in their UI.
 */
export function onConfigLoaded(configData: ConfigData): void {
  if (!isEnabled) {
    return;
  }

  Sentry.setTag('tenantSlug', configData.tenantSlug);
  Sentry.setTag('tenantUuid', configData.tenantUuid);
  Sentry.setUser({ email: configData.user?.email });
}

export default Sentry;
